/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State} from 'vuex-class'
import { PPWebModels } from '@/store/ppWebModels/types';

const namespace: string = 'ppWebModels';

export default class PpWebModelsModelChoicePage extends Vue {
	@State('ppWebModels') ppWebModels: PPWebModels | undefined;
	@Action('fetchPPModels', {namespace}) fetchPPModels: any;
	@Getter('getPPModels', {namespace}) getPPModels: any;
	@Mutation('setCurrentModel', {namespace}) setCurrentModel: any;

	onPrivat(model: Object) {
		this.setCurrentModel(model);
		this.$router.push({ name: 'p&p-web-models-view' }).then(r => r);
	}

	mounted() {
		this.fetchPPModels();
	}
}
